window.addEventListener('load', () => {
  this.form = new Form()

  const inputAmount = document.querySelector('#amount')
  const amount = document.querySelector('.amount')

  if(inputAmount) {
    inputAmount.value = ''
    inputAmount.setAttribute('placeholder', '0')
  }

  if(amount) amount.textContent = '0'

  window.checkForm = () => {
    return this.form.handleSubmit()
  }
})

class Form {
  constructor()
  {
    this.fields = document.querySelectorAll('input, textarea, select')
    this.errors = document.querySelectorAll('[data-error]')
    this.address = document.querySelector('[data-address]')
    this.addressFields = document.querySelectorAll('#street, #number, #postal_code, #city, #country')
    this.taxReceip = document.querySelector('#tax_receip')
    this.business = document.querySelector('[data-business]')
    this.company = document.querySelector('#company')
    this.businessFields = document.querySelectorAll('#business_name, #business_vat')

    this.mounted()
  }

  mounted()
  {
    this.taxReceip?.addEventListener('change', () => {
      if(this.taxReceip.checked) {
        this.address.style.display = 'block'
        this.addressFields.forEach(field => field.required = true)
      } else {
        this.address.style.display = 'none'
        this.addressFields.forEach(field => {
          field.required = false
          field.value = ''
          this.fieldValidator(field)
        })
      }
    })

    this.company?.addEventListener('change', () => {
      if(this.company.checked) {
        this.business.style.display = 'block'
        this.businessFields.forEach(field => field.required = true)
      } else {
        this.business.style.display = 'none'
        this.businessFields.forEach(field => {
          field.required = false
          field.value = ''
          this.fieldValidator(field)
        })
      }
    })

    this.fields.forEach(field => {
      if(field.getAttribute('type') !== 'checkbox') {
        field.addEventListener('blur', () => this.fieldValidator(field))
      } else {
        field.addEventListener('change', () => this.fieldValidator(field))
      }
    })
  }

  handleSubmit()
  {
    const validtors = []

    this.fields.forEach(field => validtors.push(this.fieldValidator(field)))

    return validtors.every(validtor => validtor.valid)
  }

  fieldValidator(field)
  {
    const id = field.getAttribute('id')
    const validator = this.validtor(field)

    this.hideFieldErrors(field)

    if(validator.valid) {
      field.classList.remove('is-error')
    } else {
      field.classList.add('is-error')
      document.querySelector('#' + validator.key + '_error_' + validator.error).style.display = 'block'
    }

    return validator
  }

  hideFieldErrors(field)
  {
    const id = field.getAttribute('id')
    const name = field.getAttribute('name')

    Array.from(this.errors)
        .filter(error => error.getAttribute('id').includes(id) || error.getAttribute('id').includes(name))
        .forEach(error => error.style.display = 'none')
  }

  validtor(field)
  {
    let value, key
    const type = field.getAttribute('type')
    const id = field.getAttribute('id')
    const name = field.getAttribute('name')

    if(type === 'checkbox') {
      value = field.checked
      key = id
    } else if(type === 'radio') {
      console.log(document.querySelector('input[name="' + name + '"]:checked'))
      value = document.querySelector('input[name="' + name + '"]:checked')?.value
      key = name
    } else {
      key = id
      value = field.value
    }

    if(field.hasAttribute('required') && !value) {
      return { valid: false, error: 'required', key }
    } else if(type === 'email' && this.isInvalidEmail(value)) {
      return { valid: false, error: 'email', key }
    } else if(id === 'amount' && value < 0.5) {
      return { valid: false, error: 'min', key }
    }

    return { valid: true }
  }

  isInvalidEmail(email)
  {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(email)
  }
}
